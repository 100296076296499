<template>
  <div class="mural">
    <div class="row flex no-wrap">
      <div
        class="flex items-center"
        v-if="breadcrumbs.length > 1 || breadcrumbs[0] != 'Feed'"
      >
        <q-btn
          v-show="hasGoBack"
          name="chevron_left"
          class="return-button"
          size="md"
          :to="{ name: route, query: query }"
          @click="onClickBackBtn"
          icon="chevron_left"
          flat
          rounded
        />
      </div>
      <div class="mural-label q-ml-md">
        <h5>
          <q-breadcrumbs gutter="sm">
            <q-breadcrumbs-el
              v-for="item in breadcrumbs"
              :key="item"
              :label="item"
            />
          </q-breadcrumbs>
        </h5>
        <span class="subtitle" v-if="count != null"> {{ count }}</span>
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Mural",
  props: {
    titleButton: String,
    breadcrumbs: Array,
    onClickBackBtn: Function,
    count: String,
    route: {
      type: String,
      default: "",
    },
    query: {
      type: Object,
      default: {},
    },
    hasGoBack: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    teste() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.mural {
  width: 100%;
  /* height: 15%; */
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1.5px solid #e3e8eb;
  border-radius: 50px;
  padding: 2%;

  @media (min-width: 800px) {
    padding: 1rem 1.5rem;
  }

  .return-button:hover {
    cursor: pointer;
  }

  .subtitle {
    color: var(--Grayscale-Grayscale-70, #666);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.25px;
  }

  .mural-label {
    position: relative;

    h5 {
      font-size: 1.1rem;
      font-weight: 700;
      margin: 0;
    }

    small {
      font-style: normal;
      font-weight: 500;
      font-size: 0.8rem;
      color: #00000066;
    }

    .badge-notify {
      position: absolute;
      width: 8px;
      min-height: 8px;
      padding: 0;
    }
  }
}
</style>
