<template>
  <div  class="content-number-input q-pl-lg">
    <button
      @click="stepdowmBtn"
      class="minus"
    ></button>
    <q-input
      v-model="model"
      @update:modelValue="onInput"
      type="number"
      outlined
      dense
      min="0"
      :class="custom_class_id"
      bg-color="default-white"
    />
    <button
      @click="stepUpBtn"
      class="plus"
    ></button>
  </div>
</template>

<script>
export default {
  name: "InputNumber",
  props: {
      value: {
        type: String,
        default: '0'
      }
    },
  data() {
    return {
      model: this.value,
      custom_class_id: ""
    };
  },
  methods: {
    onInput(event) {
      this.$emit("update:modelValue", event);
    },
    stepUpBtn() {
      let custom_class = `.${this.custom_class_id} input`;
      document.querySelector(custom_class).stepUp();
      this.onInput(document.querySelector(custom_class).value);
    },
    stepdowmBtn() {
      let custom_class = `.${this.custom_class_id} input`;
      document.querySelector(custom_class).stepDown();
      this.onInput(document.querySelector(custom_class).value);
    },
  },
  mounted(){
    this.custom_class_id = `custom-input-number-${Math.floor(Math.random() * (1000*587))}`;
  }
};
</script>
<style lang="scss" scoped>
.content-number-input {
  display: inline-flex;
  width: 30%;
}

.content-number-input,
.content-number-input * {
  box-sizing: border-box;
}

.content-number-input button {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  margin: 0;
  position: relative;
}

.content-number-input button:after {
  display: inline-block;
  position: absolute;
  color: var(--q-primary, #fe4e64);
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f077";
  transform: translate(-50%, -50%) rotate(180deg);
}
.content-number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(0deg);
}

.content-number-input input[type="number"] {
  font-family: sans-serif;

  padding: 0.5rem;
  border: solid #ddd;
  border-width: 0 2px;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}
</style>