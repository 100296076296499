import http from "../http-common";

export default class WareHouseService {
  async getCategories() {
    return http.get("v1/store/category");
  }

  async getProviders() {
    return http.get("v1/store/provider");
  }

  async get() {
    return http.get("v1/store/category");
  }

  async addCategories(data) {
    return http.post("v1/store/store-categories-add", data);
  }

  async disableProvider(id) {
    return http.put(`v1/store/disabled-provider?providerId=${id}`);
  }

  async getAllProviders() {
    return http.get(`v1/store/provider`);
  }

  async getProvider(providerId) {
    return http.get(`v1/store/provider/${providerId}`);
  }

  async addProvider(data) {
    return http.post("v1/store/provider", data);
  }

  async updateProvider(providerId, data) {
    return http.put(`v1/store/provider/${providerId}`, data);
  }

  async getAllProducts() {
    return http.get(`v1/store/product`);
  }

  async getProduct(productId) {
    return http.get(`v1/store/product/${productId}`);
  }

  async addProduct(data) {
    return http.post("v1/store/product", data);
  }

  async updateProduct(productId, data) {
    return http.put(`v1/store/product/${productId}`, data);
  }

  async disbeldProduct(productId) {
    return http.delete(`v1/store/product/${productId}`);
  }

  async getAllWarehouse() {
    return http.get(`v1/store/warehouse`);
  }

  async getWarehouseProduct(productId) {
    return http.get(`v1/store/warehouse/${productId}`);
  }

  async addWarehouse(data) {
    return http.post("v1/store/warehouse", data);
  }

  async getProductWarehouse(pageNumber, pageSize) {
    return http.get(
      `v1/store/product-warehouse?PageNumber=${pageNumber}&PageSize=${pageSize}`
    );
  }

  async getProductForBuy(productId) {
    return http.get(`v1/store/product-for-by/${productId}`);
  }

  async addOrder(productId, data) {
    return http.post(`v1/store/order/${productId}`, data);
  }

  async getOrders() {
    return http.get(`v1/store/order`);
  }

  async getOrder(orderId) {
    return http.get(`v1/store/order/${orderId}`);
  }

  async cancelOrder(orderId, reason_id) {
    return http.delete(`v1/store/order/${orderId}/reason/${reason_id}`);
  }

  async getReasonCancelOrder() {
    return http.get(`v1/store/order-reason-cancel`);
  }

  async getSolicitionCancelOrder() {
    return http.get(`v1/store/order-cancel`);
  }

  async GetDataOfOrderForCancel(orderId) {
    return http.get(`v1/store/order-data-of-reason-cancel/${orderId}`);
  }

  async updateCancel(orderId, data) {
    return http.post(`v1/store/order-cancel-finished/${orderId}`, data);
  }

  async getSolicitionAcceptOrder() {
    return http.get(`v1/store/order-accept`);
  }

  async acceptOrder(orderSaleId) {
    return http.post(`v1/store/order-accept/${orderSaleId}`);
  }

  async updateCategory(id, name) {
    return http.put(
      `v1/store/update-category-product?description=${name}&categoryId=${id}`
    );
  }
  async removeCategory(id) {
    return http.put(`v1/store/disabled-category-product?categoryId=${id}`);
  }
}
